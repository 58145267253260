import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "./styleComponent.css";

function Mappe() {
  const styles = {
    mapRoot: {
      height: 400,
    },
  };
  return (
    <MapContainer
      style={styles.mapRoot}
      center={[45.789673041930634, 8.872889682465317]}
      zoom={50}
      scrollWheelZoom={false}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={[45.789673041930634, 8.872889682465317]}>
        <Popup>
          A pretty CSS3 popup. <br /> Easily customizable.
        </Popup>
      </Marker>
    </MapContainer>
  );

  //}
}
export default Mappe;
