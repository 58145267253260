import React, { useState, useEffect, createContext, Fragment } from "react";
import PdfViewer from "../Components/PdfViewer.js";
import pdf from "../asset/Liste 2022.pdf";
function MenuCarta() {
  return (
    <>
      <h1 className="p-4">Menu a la Carte</h1>
      <>
        Sfoglia online la carta del Ristornate Atlantide
        <br />
        <br />
        <PdfViewer pdf={pdf} />

      </>
    </>
  );
}

export default MenuCarta;

/* <br /> 
 <i>
  NOTE PER IL CLIENTE
  <br />
  Il pesce Affumicato, Marinato o in Carpione sono alimenti sottoposti a
  trattamento di Bonifica ai sensi del reg. CE n 852/04 ed effettuato da
  ditte autorizzate.
  <br />
  Per allergie e intolleranze a cibi e bevande consultare il personale o
  consultare gli appositi allegati alla lista.
  <br />
  Le portate che contengono particolari allergeni sono evidenziate da
  una sottolineatura nella presente lista.
  <br />
  I piatti o gli ingredienti contrassegnati con “ * ” sono congelati o
  surgelati all'origine dal produttore
  <br />
  oppure sono sottoposti in loco ad abbattimento a temperatura negativa
  per garantirne la qualità e la sicurezza,
  <br />
  come descritto nelle procedure del piano HACCP ai sensi del Reg. CE
  852/04
  <br />
  Coperto €1.00
</i> */