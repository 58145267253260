import React, { useState, useEffect, createContext, Fragment } from "react";
import carne from "../asset/Carne.jpeg";
import pesce from "../asset/Pesce.jpg";
function PranziCene() {
  return (
    <>
      <h1 className="p-4">Pranzi e Cene Natalizie </h1>
      <>
        Sfoglia le proposte di Ristorante Atlantide ideali per una serata tra
        amici.
        <br />
        I fratelli Canova propongono ampia scelta tra piatti di carne, pesce,
        pizzoccheri e paella
        <br />
        Contatta ora il ristorante per costruire insieme il menù personalizzato
        per cene, rinfreschi e apericena
        <br />
        <br />
        <i>
          Pranzi e Cene Natalizie!
          <br />I menù di terra e mare per le cene aziendali natalizie 2022!
        </i>
        <br />
        <br />
        <div className="row">
          <div className="col-md-6">
            <img src={carne} alt="First slide" width="200" height="200" />
            <br />
            <br />
            <b>Menù di Terra</b>
            <br />
            Misto Salumi e Formaggi
            <br />
            ~<br />
            Risotto Fumè: Speck, Scamorza e Prosecco
            <br />
            Tortelli di Chianina ai Porcini
            <br />
            ~<br />
            Tagliata di Chianina con Contorni misti
            <br />
            ~<br />
            Panettone e Pandoro
            <br />
            con Crema al Mascarpone e Spumante
            <br />
            <br />
            <i>
              <b>Euro 35,00</b>
              <br />
              Acqua, Vino della Casa e Caffè inclusi
            </i>
            <br />
            <br />
          </div>
          <div className="col-md-6">
            <img src={pesce} alt="First slide" width="200" height="200" />
            <br />
            <br />
            <b>Menù di Mare</b>
            <br />
            Tonno e Cipolle - Muscoli Ripieni- Insalata di Mare
            <br />
            Vol-au-vent al Salmone - Involtino di Spada Affumicato e Rucola
            <br />
            Cocktail di Gamberetti
            <br />
            ~<br />
            Spaghetti allo scoglio
            <br />
            Tortelli di Mare al Granchio
            <br />
            ~<br />
            Grigliata Mista di Pesce con Contorni Misti
            <br />
            con Spada, Salmone, Tonno, Gamberi e Scampi
            <br />
            <br />
            <i>
              <b>Euro 32,00</b> - Bevande Escluse
              <br />
              oppure
              <br />
              <b>Euro 35,00</b> - Acqua, Vino della Casa e Caffè inclusi
            </i>
          </div>
        </div>
        <i>Allergeni esposti in sede</i>
        <br />
        <br />
      </>
    </>
  );
}
export default PranziCene;
