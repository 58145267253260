import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Button from "react-bootstrap/Button";
import "./styleComponent.css";

// Component PDF, passare l'url del pdf

export default function PdfViewer({ pdf }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfPageWidth, setPdfPageWidth] = useState(null);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);
    if (windowSize.innerWidth < 700) {
      setPdfPageWidth(windowSize.innerWidth - 5);
    } else {
      setPdfPageWidth(null);
    }

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const nextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const previewsPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col"></div>
        <div className="col ">
          <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} width={pdfPageWidth} />
          </Document>
        </div>
        <div className="col" pippo></div>
      </div>

      <br />
      <div className="row">
        <div className="col-md-4">
          <Button variant="primary" onClick={previewsPage}>
            Pagina precedente
          </Button>
        </div>
        <div className="col-md-4 PaddingText">
          Pagina {pageNumber} di {numPages}
        </div>
        <div className="col-md-4">
          <Button variant="primary" onClick={nextPage}>
            Pagina succesiva{" "}
          </Button>
        </div>
        <br />
        <br />
        <br />
      </div>
    </>
  );
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
}
