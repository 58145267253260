import React, { useState, useEffect, createContext, Fragment } from "react";
import WorkInProgress from "../asset/WorkInProgress.png";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

function MatrimonioPagina() {
  return (
    <>
      <h1 className="p-4">Matrimoni</h1>
      <>
        <i>Pagina in costruzione</i>
        <br />
        <br />
        A breve saranno disponibili le proposte di Ristorante Atlantide per
        festeggiare insieme un giorno davvero speciale
        <br />
        I fratelli Canova propongono un menù dinamico e personalizzabile
        <br />
        che può essere degustato presso il ristorante Atlantide o la location
        scelta
        <br />
        Si propone un ricco aperitivo a buffet, una degustazione di antipasti,
        primi e secondi
        <br />
        Alcune ulteriori opzioni: la selezione dei vini, la torta nuziale, il
        buffet dei dolci, lo show cooking e l'allestimento della location
        <br />
        Contatta ora il ristorante per costruire insieme un evento unico e
        indimenticabile
        <br />
        <br />
      </>
      {/* <img src={WorkInProgress} className="img-fluid" alt="responsive image" />
       */}
      <Row xs={1} md={2} className="g-2 p-3">
        {Array.from({ length: 2 }).map((_, idx) => (
          <Col>
            <Card>
              <Card.Img variant="top" src={WorkInProgress} />
            </Card>
          </Col>
        ))}
      </Row>
      <br />
      <br />
      <br />
    </>
  );
}

export default MatrimonioPagina;
