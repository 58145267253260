// import logo from "..//logo.svg";
import React, { useState, useEffect, createContext, Fragment } from "react";
import Container from "react-bootstrap/Container";
import { Nav, Navbar, NavDrop, NavDropdown } from "react-bootstrap";
import {
  Outlet,
  Link,
  NavLink,
  useNavigate,
  useLocation,
} from "react-router-dom";
import "./styleComponent.css";
import logo from "../asset/favicon.png";

function BarraNavigazione() {
  const location = useLocation();
  const [url, setUrl] = useState(null);
  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  console.log("Url:");
  console.log(url);
  return (
    // todo: Fare menu eventi
    //bg="dark"
    //
    <>
      <Navbar
        style={{ backgroundColor: "#f4e6c1" }}
        variant="light"
        expand="lg"
      >
        <Container>
          <Link
            to="/"
            className={url === "/" ? "LinkTitleActive" : "LinkTitleDisable"}
          >
            <img
              alt=""
              src={logo}
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{" "}
            Ristorante Atlantide
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Link
                to="/FestivitaRassegne"
                className={
                  url === "/FestivitaRassegne"
                    ? "LinkSubElementActive"
                    : "LinkSubElementDisable"
                }
              >
                Festività e Rassegne
              </Link>

              <Link
                to="/menuCarta"
                className={
                  url === "/menuCarta"
                    ? "LinkSubElementActive"
                    : "LinkSubElementDisable"
                }
              >
                Menu a la carte
              </Link>
              <NavDropdown title="Menu eventi" id="basic-nav-dropdown">
                <NavDropdown.Item href="#/Matrimonio">
                  Matrimoni
                </NavDropdown.Item>
                <NavDropdown.Item href="#/EventiCerimonie">
                  Eventi e Cerimonie
                </NavDropdown.Item>
                <NavDropdown.Item href="#/PranziCene">
                  Pranzi e Cene Natalizie
                </NavDropdown.Item>
              </NavDropdown>
              <Link
                to="/galleria"
                className={
                  url === "/galleria"
                    ? "LinkSubElementActive"
                    : "LinkSubElementDisable"
                }
              >
                Galleria
              </Link>
              <Link
                to="/contatti"
                className={
                  url === "/contatti"
                    ? "LinkSubElementActive"
                    : "LinkSubElementDisable"
                }
              >
                Contatti
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet />
    </>
  );
}

export default BarraNavigazione;
