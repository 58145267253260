import React from "react";

export default function Telefono({ numero, children }) {
  return <a href={`tel:${numero}`}>{children}</a>;
}

/*Utilizzo:
Quando si richiama passare il parametro numero  -> +39+numero
per ora ho messo come testo il numero di telefono, 
ho trovato anche questa libreria:
https://react-icons.github.io/react-icons
per le icone.
se vuoi provarla: 
comando per installarla: npm install react-icons --save

io ho fatto una prova con l'icona del telefono: 
import { FaPhoneAlt } from "react-icons/fa";
<FaPhoneAlt size="19" className="nav-linker" />
*/
