import React, { useState, useEffect, createContext, Fragment } from "react";
import "./graphic.css";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Men1 from "../asset/Menu/MenuItaliana.png";
import Men2 from "../asset/Menu/MenuValtellinese.png";
import Men3 from "../asset/Menu/MenuLunigiana.png";
import Men4 from "../asset/Menu/MenuUlisse.png";
import Men5 from "../asset/Menu/MenuNettuno.png";
import Men6 from "../asset/Menu/MenuAtlantide.png";

function BattesimoPagina() {
  var ImmaginiRiga1 = [Men1, Men2, Men3];
  var ImmaginiRiga2 = [Men4, Men5, Men6];

  return (
    <>
      <h1 className="p-4">Eventi e Cerimonie</h1>
      <div>
        I fratelli Canova propongono alcuni menù che si adattano perfettamente a
        ogni evento e cerimonia
        <br />
        Ogni proposta è completamente personalizzabile in ogni sua portata:
        <br />
        dall'aperitivo di benvenuto al taglio della torta
        <br />
        ogni piatto può essere modificato considerato i prodotti tipici della
        stagione
        <br />
        <br />
        <i>
          Contatta ora lo staff del ristorante per ulteriori informazioni,
          proposte e preventivi
        </i>
      </div>
      <div>
        <Row xs={1} md={3} className="g-3 p-3">
          {ImmaginiRiga1.map((x) => (
            <Col>
              <Card>
                <Card.Img variant="top" src={x} className="Immagine" />
              </Card>
            </Col>
          ))}
        </Row>
      </div>

      <br />

      <Row xs={1} md={3} className="g-3 p-3">
        {ImmaginiRiga2.map((x) => (
          <Col>
            <Card>
              <Card.Img variant="top" src={x} className="Immagine" />
            </Card>
          </Col>
        ))}
      </Row>
      <br />
      {/* <>
        <i>Pagina in costruzione</i>
        <br />
        <br />
        A breve saranno disponibili le proposte di Ristorante Atlantide per
        festeggiare Eventi e Cerimonie
        <br />
        I fratelli Canova propongono un menù personalizzabile in ogni sua
        portata:
        <br />
        dall'aperitivo di benvenuto al taglio della torta
        <br />
        Contatta ora il ristorante per costruire insieme il menù personalizzato
        <br />
        <br />
      </>
      <img src={WorkInProgress} className="img-fluid" alt="responsive image" />
      <br />
      <br />
      <br /> */}
    </>
  );
}

export default BattesimoPagina;
