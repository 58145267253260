import React from "react";
import Mailto from "../Components/mail.js";
import Telefono from "../Components/Telefono.js";

function InformazioniRistorante() {
  return (
    <>
      <div className="row graficaInformazioni ">
             <center> <i>
     Per qualsiasi informazione su sostanze e allergeni sottolineati nei menù di questo sito web, <br />
     è possibile consultare la relativa documentazione che verrà fornita, a richiesta, dal personale di servizio. <br /><br />
        </i> </center>
      </div>

      <div className="row graficaInformazioni ">
        <div className="col-xs-12 col-md-8 ">
          <b>Ristorante Atlantide</b>
          <br />
          via del Bollerino 3, 21046 Malnate -VA- Italia
          <br />
          telefono:{" "}
          <Telefono numero={"+390332861496"} children={"+39 0332861496"} /> -
          mobile:{" "}
          <Telefono numero={"+393483179681"} children={"+39 3483179681"} />
          <br />
          email:{" "}
          <Mailto
            email={"info@ristoranteatlantide.com"}
            children={"info@ristoranteatlantide.com"}
          />
          <br />
        </div>
        <div className="col-xs-6 col-md-4">
          <b>Apertura:</b>
          <br />
          Chiuso il giovedì sera
          <br />
          Aperto tutto gli altri giorni a pranzo e cena
        </div>
      </div>
      <i>Tutti i diritti sono riservati, ©Copyright 2022 - 2023</i>
      <br />
      <br />
      Non si effettua la raccolta dei dati personali dell'utente
      <br />
      <br />
    </>
  );
}
export default InformazioniRistorante;
