import React, { useState, useEffect, createContext, Fragment } from "react";
import Mappe from "../Components/Mappe.js";
import Mailto from "../Components/mail.js";
import Telefono from "../Components/Telefono.js";

function Contatti() {
  return (
    <>
      <h1 className="p-4">Contatti</h1>
      <div>
        <i>Gradita la prenotazione</i>
        <br />
        I fratelli Canova e Ristorante Atlantide sono in
        <br />
        via del Bollerino 3, 21046 Malnate -VA- Italia
        <br />
        telefono:{" "}
        <Telefono numero={"+390332861496"} children={"+39 0332861496"} /> -
        mobile:{" "}
        <Telefono numero={"+393483179681"} children={"+39 3483179681"} />
        <br />
        email:{" "}
        <Mailto
          email={"info@ristoranteatlantide.com"}
          children={"info@ristoranteatlantide.com"}
        />
        <br />
        <div>
          <div className="row">
            <div className="col-md-6"> </div>
            <div className="col-md-6"> </div>
          </div>
        </div>
        <br />
        <Mappe />
        <br />
      </div>
    </>
  );
}
export default Contatti;

/* 
telefono e mail affiancati nel div + sotto la cartina 
= vedendolo così mi sembra aposto, non vorrei sparpagliare le info

TODO: Nella mail è possibile specificare un soggetto, e un oggetto chiedere cosa si vuole mettere. 
*/
