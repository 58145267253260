import React, { useState, useEffect, createContext, Fragment } from "react";
import base from "../asset/LogoGrande.JPG";
//import Natale2022 from "../asset/Natale2022.jpg";

//import Natale2022 from "../asset/ImmagineNataleCopertina.png";
//import Capodanno2022 from "../asset/ImmagineCapodannoCopertina.png";
//import pdf from "../asset/sample.pdf";
import Capodanno from "../asset/ImmagineCapodannoCopertina2.png";
import Natale from "../asset/ImmagineNataleCopertina.png";
//import SV_Copertina from "../asset/ImmagineSValentino_Copertina.png";
import pasquaImg from "../asset/ImmaginePasqua23_Copertina.png";
import "./graphic.css";

import Carousel from "react-bootstrap/Carousel";
import Mappe from "../Components/Mappe.js";
import Social from "../Components/Social.js";
import PdfViewer from "../Components/PdfViewer.js";

//const style = { objectFit: "cover" };

/*
 height={400}
*/
function Home() {
  return (
    <>
      {" "}
      <div style={{ backgroundColor: "#f7e6b7" }}>
        <Carousel variant="dark">
          <Carousel.Item>
            <img
              className=" d-block w-100 immagine"
              src={base}
              alt="First slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <a href="/#/FestivitaRassegne">
              <img
                className="d-block w-100 immagine"
                src={pasquaImg}
                alt="Second slide"
              />
            </a>
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>

          {/*<Carousel.Item>
           <a href="/#/FestivitaRassegne"> 
            <img
              className="d-block w-100 immagine"
              src={Capodanno}
              alt="Thirds Slide"
            />
            </a>
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <a href="/#/FestivitaRassegne">
              <img
                className="d-block w-100 immagine"
                src={Capodanno2022}
                alt="Second slide"
              />
            </a>
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <a href="/#/FestivitaRassegne">
              <img
                className="d-block w-100 immagine"
                src={Natale2022}
                alt="Third slide"
              />
            </a>
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
           <Carousel.Item>
            <img
              className="d-block w-100 immagine"
              src={natale}
              alt="Third slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item> */}
        </Carousel>

        <h1 className="p-4">
          Benvenuti al Ristorante Atlantide, insieme dal 1992
        </h1>
      </div>
      <div className="row">
        <div className="col-md-6">
          {" "}
          <Social />
          <br />
          <br />
          <i>
            {" "}
            <h3>
              <i> News!! </i>
            </h3>
            Scopri le ultime novita nella sezione Festività e Rassegne: <br />
            <ul>
              <li>
                <a href="/#/FestivitaRassegne">
                  <b> Menù Pranzo Santa Pasqua 2024 </b>
                </a>
              </li>

              {/*<li>
                <a href="/#/FestivitaRassegne">
                  <b>Cenone con Ballo di Capodanno</b>
                </a>
              </li>
              <li>
                <a href="/#/FestivitaRassegne">
                  <b>Proposta Pranzo di Natale </b>
                </a>
              </li>
              <li>
                <a href="/#/PranziCene">
                  <b>
                    {" "}
                    I menù di terra e mare per i pranzi e cene natalizie 2022!
                  </b>
                </a>
                
              </li>*/}
            </ul>
          </i>
        </div>
        <div className="col-md-6">
          {" "}
          <Mappe />
        </div>
      </div>
      <br />
    </>
  );
}
export default Home;
