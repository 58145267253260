import "./App.css";
import React, { Component } from "react";
//import { HashRouter, Route, Link, Switch } from "react-router-dom";
import { HashRouter, Route, Link, Routes } from "react-router-dom";
import Home from ".//Pages//Home.js";
import FestivitaRassegne from "./Pages/FestivitaRassegne.js";
import MenuCarta from ".//Pages/MenuCarta.js";
import PranziCene from "./Pages/PranziCene";
import EventiCerimonie from ".//Pages/EventiCerimonie";
import MatrimonioPagina from ".//Pages/MatrimonioPagina";
import Galleria from ".//Pages/Galleria.js";
import Contatti from ".//Pages/Contatti.js";
import BarraNavigazione from ".//Components/BarraNavigazione.js";
import InformazioniRistorante from ".//Components/InformazioniRistorante.js";

function App() {
  return (
    <HashRouter>
      <div className="App">
        <BarraNavigazione />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route
            exact
            path="/FestivitaRassegne"
            element={<FestivitaRassegne />}
          />
          <Route exact path="/menuCarta" element={<MenuCarta />} />
          <Route exact path="/PranziCene" element={<PranziCene />} />
          <Route exact path="/EventiCerimonie" element={<EventiCerimonie />} />
          <Route exact path="/Matrimonio" element={<MatrimonioPagina />} />
          <Route exact path="/galleria" element={<Galleria />} />
          <Route exact path="/contatti" element={<Contatti />} />
        </Routes>
        <InformazioniRistorante />
      </div>
    </HashRouter>
  );
}

export default App;
