import React, { useState, useEffect, createContext, Fragment } from "react";
//import WorkInProgress from "../asset/WorkInProgress.png";
// Location
import Location1 from "../asset/Location/img1.jpeg";
import Location2 from "../asset/Location/img2.jpeg";
import Location3 from "../asset/Location/img3.jpeg";
import Location4 from "../asset/Location/img4.jpeg";
import Location5 from "../asset/Location/img5.jpeg";
import Location6 from "../asset/Location/img6.jpeg";
import Location7 from "../asset/Location/img7.jpeg";
import Location8 from "../asset/Location/img8.jpeg";
import Location9 from "../asset/Location/img9.jpeg";
import Location10 from "../asset/Location/img10.jpeg";
import Location11 from "../asset/Location/img11.jpeg";
import Location12 from "../asset/Location/img12.jpeg";
import Location13 from "../asset/Location/img13.jpeg";
import Location14 from "../asset/Location/img14.jpeg";

// Piatti
import Piatti1 from "../asset/Piatti/ale.jpg";
import Piatti2 from "../asset/Piatti/ale2.jpg";
import Piatti3 from "../asset/Piatti/pesce.jpg";

import CustomCarousel from "../Components/CustomCarousel.js";

import "./graphic.css";
function Galleria() {
  var immaginiLocation = [
    Location1,
    Location2,
    Location3,
    Location4,
    Location5,
    Location6,
    Location7,
    Location8,
    Location9,
    Location10,
    Location11,
    Location12,
    Location13,
    Location14,
  ];
  var immaginiPiatti = [Piatti1, Piatti2, Piatti3];
  return (
    <>
      <h1 className="p-4">Galleria</h1>
      <>
        <i>
          Alcune istantanee direttamente dalle sale e dalla cucina del
          Ristorante Atlantide
        </i>
      </>
      <br />
      <br />
      <h4>Location </h4>
      <CustomCarousel immagini={immaginiLocation}></CustomCarousel>
      <br />
      <h4>Piatti </h4>
      <CustomCarousel immagini={immaginiPiatti}></CustomCarousel>
      <br />
      <br />
    </>
  );
}
export default Galleria;
