import React from "react";

function Mailto({ email, subject = "", body = "", children }) {
  let params = subject || body ? "?" : "";
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;

  return <a href={`mailto:${email}${params}`}>{children}</a>;
}

export default Mailto;

/*
utilizzo: 
campo email: email del destinatario OBBLGATORIO 
subject = oggetto della mail. 
body = "testo della mail"
 <Mailto
        email="morotto91@outlook.it"
        subject="Hello & Welcome"
        body="Hello world!"
      >
        messaggio
      </Mailto>
*/
