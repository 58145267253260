import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Carousel from "react-bootstrap/Carousel";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export default function CustomCarousel({ immagini }) {
  const [show, setShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const handleClose = () => {
    setShow(false);
    setSelectedImage("");
  };

  return (
    <>
      <Row>
        <Col></Col>
        <Col>
          <Carousel className="p-3" variant="dark">
            {immagini.map((x) => (
              <Carousel.Item>
                <img
                  className="immagine"
                  src={x}
                  alt="First slide"
                  onClick={() => {
                    setSelectedImage(x);
                    setShow(true);
                  }}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
        <Col></Col>
      </Row>

      <Modal
        show={show}
        size="xl"
        //fullscreen="xxl-down"
      >
        <Modal.Body style={{ backgroundColor: "black" }}>
          <Row>
            <Col></Col>
            <Col>
              <img className="immagine" src={selectedImage} alt="First slide" />
              <br />
            </Col>
            <Col></Col>
          </Row>
          <br />
          <Row>
            <Col>
              {" "}
              <Button onClick={handleClose}>Chiudi</Button>
            </Col>
            <Col></Col>
            <Col></Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
